@import "../../dx-styles.scss";
@import "../../themes/generated/variables.additional.scss";

.side-navigation-menu {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
  width: 250px !important;

  .menu-container {
    min-height: 100%;
    display: flex;
    flex: 1;
    background-color: #FFFFFF;


    .dx-treeview {
      // ## Long text positioning
      white-space: nowrap;
      // ##

      // ## Icon width customization
      .dx-treeview-item {
        padding-left: 0;
        flex-direction: row-reverse;
        color: #005CBB;

        .dx-icon {
          width: $side-panel-min-width !important;
          margin: 0 !important;
          filter: invert(24%) sepia(45%) saturate(3456%) hue-rotate(197deg) brightness(95%) contrast(102%);;
        }
      }
      // ##
      
      // ## Arrow customization
      .dx-treeview-node {
        padding: 0 0 !important;
      }

      .dx-treeview-toggle-item-visibility {
        right: 10px;
        left: auto;
      }

      .dx-rtl .dx-treeview-toggle-item-visibility {
        left: 10px;
        right: auto;
      }
      // ##

      // ## Item levels customization
      .dx-treeview-node {
        &[aria-level='1'] {
          font-weight: bold;
          // border-bottom: 1px solid $base-border-color;
        }
  
        &[aria-level='2'] .dx-treeview-item-content {
          font-weight: normal;
          padding: 0 $side-panel-min-width;
        }
      }
      // ##
    }

    // ## Selected & Focuced items customization
    .dx-treeview {
      .dx-treeview-node-container {
        .dx-treeview-node {
          &.dx-state-selected:not(.dx-state-focused)> .dx-treeview-item {
            // background: transparent;
            background-color: #D7E3FF;       
            color:#005CBB;     
            fill:#005CBB;
          }

          &.dx-state-selected > .dx-treeview-item * {
            // color: $base-accent;
            color: #001B3F;
            fill: #001B3F;
          }

          &.dx-state-selected > .dx-treeview-item .dx-icon{
            filter: invert(0%);
          }

          &:not(.dx-state-focused)>.dx-treeview-item.dx-state-hover {
            // background-color: lighten($base-bg, 4.00);
            background-color: #D7E3FF;
          }
        }
      }
    }

    .dx-theme-generic .dx-treeview {
      .dx-treeview-node-container .dx-treeview-node.dx-state-selected.dx-state-focused > .dx-treeview-item * {
        color: inherit;
        // color: #005CBB;
      }
    }
    // ##
  }
}

.dx-drawer-overlap.pre-init-blink-fix {
  @import "../../utils/patches.scss";
  @include menu-pre-init-patch;
}
